<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
       <el-form :model="form" :rules="rules" ref="form" style="width:700px">
        <el-form-item label="终端编号" prop="schema" label-width="250px">
          <el-select v-model="form.schema" :disabled="edit">
            <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="edit" label="客户端授权编号" label-width="250px">
          <el-input v-model="form.client_id" :disabled="edit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="秘钥" v-if="edit" label-width="250px">
          <el-input v-model="temp.secret" :disabled="edit" autocomplete="off"></el-input>
          <el-button v-if="edit" class="showbtn" size="mini" type="info" @click="Look('secret')">{{btnlist.secret}}</el-button>
        </el-form-item>
        <el-form-item label="定向地址" prop="redirect_uri" label-width="250px">
          <el-input type="textarea" :rows="5" v-model="form.redirect_uri" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="授权类型" prop="grant_types" label-width="250px">
          <el-select v-model="form.grant_types" placeholder="请选择">
            <el-option label="授权码(authorization-code)" :value="'authorization-code'"></el-option>
            <el-option label="隐藏式(implicit)" :value="'implicit'"></el-option>
            <el-option label="密码式(password)" :value="'password'"></el-option>
            <el-option label="客户端凭证(client credentials)" :value="'client credentials'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="scope" prop="scope" label-width="250px">
          <el-input v-model="form.scope" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="alexa_skill_id" prop="alexa_skill_id" label-width="250px">
          <el-input v-model="form.alexa_skill_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="alexa_client_id" prop="alexa_client_id" label-width="250px">
          <el-input v-model="form.alexa_client_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="alexa_secret" prop="alexa_secret" label-width="250px">
          <el-input :disabled="edit&&btnlist.alexa_secret=='显示'" v-model="temp.alexa_secret" autocomplete="off"></el-input>
          <el-button v-if="edit" class="showbtn" size="mini" type="info" @click="Look('alexa_secret')">{{btnlist.alexa_secret}}</el-button>
        </el-form-item>
        <el-form-item label="google_project_id" prop="google_project_id" label-width="250px">
          <el-input v-model="form.google_project_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="google_private_key_id" prop="google_private_key_id" label-width="250px">
          <el-input v-model="form.google_private_key_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="google_private_key" prop="google_private_key" label-width="250px">
          <el-input type="textarea" :rows="5" :disabled="edit&&btnlist.google_private_key=='显示'" v-model="temp.google_private_key" autocomplete="off"></el-input>
          <el-button v-if="edit" class="showbtn" size="mini" type="info" @click="Look('google_private_key')">{{btnlist.google_private_key}}</el-button>
        </el-form-item>
        <el-form-item label="google_client_email" prop="google_client_email" label-width="250px">
          <el-input v-model="form.google_client_email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="google_client_id" prop="google_client_id" label-width="250px">
          <el-input v-model="form.google_client_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="google_auth_uri" prop="google_auth_uri" label-width="250px">
          <el-input v-model="form.google_auth_uri" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="google_token_uri" prop="google_token_uri" label-width="250px">
          <el-input v-model="form.google_token_uri" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="google_auth_provider_x509_cert_url" prop="google_auth_provider_x509_cert_url" label-width="250px">
          <el-input v-model="form.google_auth_provider_x509_cert_url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="google_client_x509_cert_url" prop="google_client_x509_cert_url" label-width="250px">
          <el-input v-model="form.google_client_x509_cert_url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label-width="250px">
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </el-form-item>
      </el-form>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      edit: false,
      clientId: '',
      title: '',
      form: {
        client_id: '',
        schema: '',
        secret: '',
        redirect_uri: '',
        grant_types: '',
        scope: '',
        alexa_skill_id: '',
        alexa_client_id: '',
        alexa_secret: '',
        google_project_id: '',
        google_private_key_id: '',
        google_private_key: '',
        google_client_email: '',
        google_client_id: '',
        google_auth_uri: '',
        google_token_uri: '',
        google_auth_provider_x509_cert_url: '',
        google_client_x509_cert_url: ''
      },
      rules: {
        schema: [{ required: true, message: '请输入终端编号', trigger: 'blur' }],
        secret: [{ required: true, message: '请输入秘钥', trigger: 'blur' }],
        // redirect_uri: [{ required: true, message: '请输入定向地址', trigger: 'blur' }],
        grant_types: [{ required: true, message: '请输入授权类型', trigger: 'blur' }],
        // scope: [{ required: true, message: '请输入鉴权字段', trigger: 'blur' }],
        // alexa_skill_id: [{ required: true, message: '请输入alexa_skill_id', trigger: 'blur' }],
        // alexa_client_id: [{ required: true, message: '请输入alexa_client_id', trigger: 'blur' }],
        // alexa_secret: [{ required: true, message: '请输入alexa_secret', trigger: 'blur' }],
        // google_project_id: [{ required: true, message: '请输入google_project_id', trigger: 'blur' }],
        // google_private_key_id: [{ required: true, message: '请输入google_private_key_id', trigger: 'blur' }],
        // google_private_key: [{ required: true, message: '请输入google_private_key', trigger: 'blur' }],
        // google_client_email: [{ required: true, message: '请输入google_client_email', trigger: 'blur' }],
        // google_client_id: [{ required: true, message: '请输入google_client_id', trigger: 'blur' }],
        // google_auth_uri: [{ required: true, message: '请输入google_auth_uri', trigger: 'blur' }],
        // google_token_uri: [{ required: true, message: '请输入google_token_uri', trigger: 'blur' }],
        // google_auth_provider_x509_cert_url: [{ required: true, message: '请输入google_auth_provider_x509_cert_url', trigger: 'blur' }],
        // google_client_x509_cert_url: [{ required: true, message: '请输入google_client_x509_cert_url', trigger: 'blur' }],
      },
      applist: [],
      temp: {
        secret: '*****',
        alexa_secret: '*****',
        google_private_key: '*****',
      },
      // 按钮
      btnlist: {
        secret: '显示',
        alexa_secret: '显示',
        google_private_key: '显示',
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    Look (item) {
      if (item == 'secret' && this.btnlist[item]=='显示') {
        this.api.ClientSecret({
          client_id: this.form.client_id
        }).then(res => {
          if (res.data.code == 200) {
          this.temp.secret = res.data.data.secret
          this.btnlist[item] = '隐藏'
          return
        }
        this.$message.error('获取app列表失败' + res.data.msg)
        })
        return
      }
      if(this.btnlist[item]=='显示'){
        this.btnlist[item] = '隐藏'
        this.temp[item] = this.form[item]
      } else {
        this.btnlist[item] = '显示'
        this.temp[item] = '*****'
      }
    },
    Getschema() {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          return
        }
        this.$message.error('获取app列表失败' + res.data.msg)
      })
    },
    GetInfo () {
      this.api.ClientUserDetail({client_id: this.clientId}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm(){
      this.update()
    },
    update(){
      this.form.google_private_key = this.temp.google_private_key=='*****'?this.form.google_private_key:this.temp.google_private_key
      this.form.alexa_secret = this.temp.alexa_secret=='*****'?this.form.alexa_secret:this.temp.alexa_secret
      this.api.ClientUserUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm(this.title+'成功，是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    editconfirm(){
      this.update()
    },
    goback () {
      this.$router.push('/iot/client')
    }
  },
  filters: {},
  mounted () {
    this.clientId = this.$route.params.id
    if (this.clientId !== '0') {
      this.GetInfo()
      this.title =  '修改'
      this.edit = true
    } else {
        this.title =  '新增'
        this.edit = false
        this.temp.secret = ''
        this.temp.alexa_secret = ''
        this.temp.google_private_key = ''
    }
  },
  created () {
    this.Getschema()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.showbtn {
  position: absolute;margin-top: 6px;margin-left: 5px;
}
</style>
